export const USER_ROLES = [
  { id: "admin", name: "Administrator" },
  { id: "parent", name: "Parent" },
  { id: "teacher", name: "Teacher" },
  { id: "customer_experience", name: "Customer experience" },
  { id: "student", name: "Student" },
  { id: "editor", name: "Editor" },
];

export const USER_SOURCES = [
  { id: "stripe", name: "Stripe" },
  { id: "manual", name: "Manual" },
  { id: "web", name: "Web" },
  { id: "web-join", name: "Web - Default Join Flow" },
  { id: "web-redeem", name: "Web - Redeem Flow" },
  { id: "web-start", name: "Web - Start Flow" },
  { id: "web-free-teacher", name: "Web - Free Teacher" },
  { id: "web-read-along", name: "Web - Read Along" },
  { id: "ios", name: "iOS" },
  { id: "mackinvia.com", name: "MackinVia" },
  { id: "clever.com", name: "Clever Library" },
  { id: "clever.com/secure-sync", name: "Clever Sync" },
  { id: "read-along", name: "Read-along" },
  { id: "partner-register", name: "Partner register" },
  { id: "android", name: "Android" },
  { id: "dot-convertex", name: "DotConvertecs" },
  { id: "atmos", name: "Atmos Energy Signup" },
];

export const SUBSCRIPTION_STORES = [
  { id: "app_store", name: "App store" },
  { id: "play_store", name: "Play store" },
  { id: "stripe", name: "Stripe" },
];

const DEVELOPMENT_PRICE_IDS = [
  { id: "price_1LkwRYDaFspz7lmPUskGf3i4", name: "Free Lifetime Subscription" },
  { id: "price_1LKOvADaFspz7lmP9EMP3gVT", name: "Free Homeschool" },
  { id: "price_1P9qP0DaFspz7lmPbJvP0Lgk", name: "Monthly 6.99" },
  { id: "price_1HHxcvDaFspz7lmPQxpWck7J", name: "Yearly 49.99" },
];

const PRODUCTION_PRICE_IDS = [
  { id: "price_prod_1234", name: "Free Teacher" },
  { id: "price_prod_5678", name: "Free Homeschool" },
  { id: "price_prod_9012", name: "Monthly 6.99" },
  { id: "price_prod_3456", name: "Yearly 49.99" },
];
export const PRICE_IDS =
  process.env.NODE_ENV === "production"
    ? PRODUCTION_PRICE_IDS
    : DEVELOPMENT_PRICE_IDS;
