import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Pagination,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanField,
} from "react-admin";

const TagsPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const MyReferenceField = (props) => {
  return (
    <ReferenceField {...props}>
      <TextField source="name" />
    </ReferenceField>
  );
};

const PathField = ({ record, source }) => {
  if (record[source]) {
    let splittedValue = record[source].split("/");

    const [first, second, ...rest] = splittedValue;

    return (
      <span>
        <b>{first.toUpperCase()}</b>
        {second && (
          <>
            {" "}
            / <b>{second}</b>
          </>
        )}
        {rest.length ? ` / ${rest.join(" / ")}` : ""}
      </span>
    );
  }

  return null;
};

const TagFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn resettable />
    <TextInput label="Name" source="name" resettable />
    <TextInput label="Code" source="code" resettable />
    <ReferenceInput label="Parent" source="parent" reference="tags">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const TagsList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={20}
      pagination={<TagsPagination />}
      filters={<TagFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="code" />
        <PathField source="path" sortable={false} />
        <MyReferenceField
          source="parent"
          label="Parent"
          reference="tags"
          sortable={false}
        >
          <TextField source="name" />
        </MyReferenceField>
        <BooleanField source="is_interest" />
        <BooleanField source="is_filter" />
        <BooleanField source="is_custom" />
        <BooleanField source="is_visible" />
      </Datagrid>
    </List>
  );
};
