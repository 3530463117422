import React, { useState, useEffect, useCallback } from "react";
import "./book.css";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  FileInput,
  FileField,
  Confirm,
  useRefresh,
  useNotify,
  BooleanInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { DateInput } from "react-admin-date-inputs2";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { red } from "@material-ui/core/colors";
import {
  patchForMuxData,
  updateVideoStatus,
  uploadImage,
  getImageByScope,
  getBookVideo,
  deleteImageByScope,
  deleteBookVideo,
  getBookTags,
  getBookActivities,
} from "../../services/book";
import { removeActivity } from "../../services/activitiy";
import {
  addTagToBook,
  getAllTags,
  removeTagFromBook,
} from "../../services/tag";
import {
  validateTitle,
  validateFieldLength,
  validateShortFieldLength,
  validateAgeLevelField,
  validateSlug,
} from "../../util";

const UpChunk = require("@mux/upchunk");

const VideoUploader = ({ record, id }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [hasMuxError, setHasMuxError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [video, setVideo] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [progress, setProgress] = useState(0);
  const [muxUpload, setMuxUpload] = useState(null);

  useEffect(() => {
    const getVideo = async () => {
      try {
        const { data } = await getBookVideo(id);
        setVideo(data);
      } catch (error) {
        setVideo(null);
      }
    };

    if (record.video_status === "upload_completed" && !video) {
      getVideo();
    }
  }, [id, record.video_status, video]);

  const cancelUpload = () => {
    muxUpload.abort();
    refresh();
  };

  const uploadVideoToMux = (file) => {
    if (file) {
      setUploading(true);

      patchForMuxData(id)
        .then(({ data }) => {
          return data;
        })
        .then((muxData) => {
          const upload = UpChunk.createUpload({
            file,
            endpoint: muxData.url,
          });

          setMuxUpload(upload);

          upload.on("progress", (progress) => {
            setProgress(progress.detail);
          });

          upload.on("success", () => {
            updateVideoStatus({
              video_id: muxData.id,
              status: "upload_completed",
            })
              .then(() => {
                return getBookVideo(id);
              })
              .then((res) => {
                setVideo(res.data);
              })
              .catch((error) => {
                notify(
                  "Not possible to upload this video, please try again",
                  "warning"
                );
                setVideo(null);
              })
              .finally(refresh);
          });
        })
        .catch(() => {
          setUploading(false);
          setHasMuxError(true);
        });
    }
  };

  const deleteVideo = () => {
    deleteBookVideo(id).then(refresh);
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleCancelUploadClick = () => setOpenCancel(true);
  const handleDialogCancelClose = () => setOpenCancel(false);

  return (
    <div className="video-uploader">
      {!hasMuxError &&
        !uploading &&
        !video &&
        record.video_status !== "upload_completed" && (
          <FileInput
            source="video"
            accept="video/mp4"
            label="Video"
            onChange={uploadVideoToMux}
          >
            <FileField source="src" title="name" />
          </FileInput>
        )}
      {hasMuxError && (
        <span>
          Some error ocurred and you are not able to upload a video at this
          moment.
        </span>
      )}
      {uploading && (
        <>
          <LinearProgress variant="determinate" value={progress} />
          <>
            <span style={{ display: "block", marginTop: 8 }}>
              Uploading Video..
            </span>
            {muxUpload && (
              <Button
                className="btn-delete"
                variant="contained"
                color="primary"
                size="small"
                style={{
                  marginTop: 8,
                  backgroundColor: red[500],
                  width: 165,
                }}
                onClick={handleCancelUploadClick}
              >
                Cancel Upload
              </Button>
            )}
          </>
        </>
      )}
      {video && (
        <FormControl>
          <FormLabel
            focused={false}
            style={{
              transform: "translate(0, 1.5px) scale(0.75)",
              transformOrigin: "top left",
            }}
          >
            Video
          </FormLabel>
          <ReactPlayer
            controls
            url={video.url}
            style={{ marginBottom: "8px" }}
          />
          <Button
            className="btn-delete"
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            size="small"
            style={{
              backgroundColor: red[500],
              width: 165,
            }}
            onClick={handleClick}
          >
            DELETE VIDEO
          </Button>
        </FormControl>
      )}
      <Confirm
        isOpen={open}
        title="Delete video"
        content="Are you sure you want to delete this video?"
        onConfirm={deleteVideo}
        onClose={handleDialogClose}
      />
      <Confirm
        isOpen={openCancel}
        title="Cancel video upload"
        content="Are you sure you want to cancel the upload of your video?"
        onConfirm={cancelUpload}
        onClose={handleDialogCancelClose}
      />
    </div>
  );
};

const ImageUploader = ({ record }) => {
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);
  const [imageScope, setImageScope] = useState("cover");
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getImage = () => {
      setImage(null);

      getImageByScope({
        bookId: record.id,
        imageScope,
      })
        .then((res) => {
          setImage(res.data);
        })
        .catch(() => {
          setImage(null);
        });
    };

    if (imageScope) {
      getImage();
    }
  }, [imageScope, record.id]);

  const uploadThisImage = (file) => {
    if (file) {
      setUploading(true);
      // Upload image API call
      uploadImage({ bookId: record.id, imageScope, imageFile: file }).then(
        () => {
          getImageByScope({
            bookId: record.id,
            imageScope,
          })
            .then((res) => {
              setImage(res.data);
            })
            .catch(() => {
              setImage(null);
            })
            .finally(refresh);
        }
      );
    }
  };

  const deleteThisImage = () => {
    deleteImageByScope({
      bookId: record.id,
      imageScope,
    }).then(refresh);
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel
          focused={false}
          style={{
            transform: "translate(0, 1.5px) scale(0.75)",
            transformOrigin: "top left",
          }}
        >
          Image type
        </FormLabel>

        <RadioGroup
          aria-label="imageType"
          name="imageType"
          value={imageScope}
          onChange={(e) => setImageScope(e.target.value)}
          row
        >
          <FormControlLabel
            value="cover"
            control={<Radio color="primary" />}
            label="Card"
            disabled={uploading}
          />
          <FormControlLabel
            value="poster"
            control={<Radio color="primary" />}
            label="Poster/Cover"
            disabled={uploading}
          />
          <FormControlLabel
            value="background"
            control={<Radio color="primary" />}
            label="Background"
            disabled={uploading}
          />
        </RadioGroup>
      </FormControl>
      {!uploading && (
        <>
          {!image && (
            <FileInput
              source="image"
              label="Image"
              accept="image/*"
              onChange={uploadThisImage}
            >
              <></>
            </FileInput>
          )}
          {image && (
            <>
              <img className="image-preview" src={image.imageUrl} alt="" />
              <Button
                className="btn-delete"
                variant="contained"
                color="primary"
                startIcon={<DeleteIcon />}
                size="small"
                style={{
                  backgroundColor: red[500],
                  width: 165,
                }}
                onClick={handleClick}
              >
                DELETE IMAGE
              </Button>
            </>
          )}
        </>
      )}
      {uploading && (
        <>
          <LinearProgress />
          <span style={{ display: "block", marginTop: 8 }}>
            Uploading Image..
          </span>
        </>
      )}
      <Confirm
        isOpen={open}
        title="Delete image"
        content="Are you sure you want to delete this image?"
        onConfirm={deleteThisImage}
        onClose={handleDialogClose}
      />
    </>
  );
};

const ActivitiesForm = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [bookActivities, setBookActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [activityIdSelected, setActivityIdSelected] = useState(null);

  const getAndSetBookActivities = useCallback(async () => {
    setLoading(true);
    try {
      const { data: { activities } } = await getBookActivities(record.id, { pageSize: 10000 });
      setBookActivities(activities || []);
    } catch (error) {
      notify(
        "An error ocurred trying to get the book activities, please refresh and try again",
        "warning"
      );
    } finally {
      setLoading(false);
    }
  }, [record, notify]);

  useEffect(() => {
    getAndSetBookActivities();
  }, [record, getAndSetBookActivities]);

  const handleDeleteClick = (id) => {
    setActivityIdSelected(id);
    setOpen(true);
  };

  const handleDialogClose = () => setOpen(false);
  const deleteActivity = async () => {
    try {
      await removeActivity(activityIdSelected);
    } catch (error) {
      notify(
        "An error ocurred trying to remove this activity, please try again",
        "warning"
      );
    } finally {
      refresh();
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Resource</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookActivities &&
            bookActivities.map(({ id, name, description, resource_uri }) => (
              <TableRow>
                <TableCell>{name}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>
                  <Link href={resource_uri}>{resource_uri}</Link>
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    className="btn-delete"
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    size="small"
                    style={{
                      backgroundColor: red[500],
                      width: 165,
                    }}
                    onClick={() => handleDeleteClick(id)}
                  >
                    DELETE
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={4}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Confirm
        isOpen={open}
        title="Delete Activity"
        content="Are you sure you want to delete this Activity?"
        onConfirm={deleteActivity}
        onClose={handleDialogClose}
      />
    </>
  );
};

const TagsForm = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [tags, setTags] = useState([]);
  const [bookTags, setBookTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tagIdSelected, setTagIdSelected] = useState(null);
  const [isAddingTag, setIsAddingTag] = useState(false);

  const getAndSetBookTags = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getBookTags(record.id);
      setBookTags(data || []);
    } catch (error) {
      notify(
        "An error ocurred trying to get the book tags, please refresh and try again",
        "warning"
      );
    } finally {
      setLoading(false);
    }
  }, [record, notify]);

  useEffect(() => {
    const getAndSetTags = async () => {
      const { data } = await getAllTags();
      setTags(data.tags);
    };

    getAndSetTags();
    getAndSetBookTags();
  }, [record, getAndSetBookTags]);

  const addThisTagToBook = async (event, value) => {
    setIsAddingTag(true);
    event.preventDefault();

    try {
      await addTagToBook({ book_id: record.id, tag_id: value.id });
    } catch (error) {
      notify(
        "An error ocurred trying to add this tag to the book, please try again",
        "warning"
      );
    } finally {
      setIsAddingTag(false);
      refresh();
    }
  };

  const handleDeleteClick = (id) => {
    setTagIdSelected(id);
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const deleteTagFromBook = async () => {
    try {
      await removeTagFromBook(tagIdSelected, record.id);
    } catch (error) {
      notify(
        "An error ocurred trying to remove this tag to the book, please try again",
        "warning"
      );
    } finally {
      refresh();
    }
  };

  const checkIfDisabled = (tag) => {
    return bookTags.some((t) => t.id === tag.id);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookTags &&
            bookTags.map(({ id, name, code }) => (
              <TableRow>
                <TableCell>{name}</TableCell>
                <TableCell>{code}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    className="btn-delete"
                    variant="contained"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    size="small"
                    style={{
                      backgroundColor: red[500],
                      width: 165,
                    }}
                    onClick={() => handleDeleteClick(id)}
                  >
                    DELETE
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={3}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Autocomplete
        options={tags}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => checkIfDisabled(option)}
        style={{ width: 300, marginTop: 16 }}
        disableClearable
        disabled={isAddingTag}
        onChange={addThisTagToBook}
        renderInput={(params) => (
          <TextField {...params} label="Add Tag" variant="outlined" />
        )}
      />
      <Confirm
        isOpen={open}
        title="Delete tag"
        content="Are you sure you want to delete this tag from this book?"
        onConfirm={deleteTagFromBook}
        onClose={handleDialogClose}
      />
    </>
  );
};

const BookTitle = ({ record }) => (
  <span>Book {record ? `"${record.title}"` : "edit"}</span>
);

const useStyles = makeStyles({
  toolbar: { display: "flex", justifyContent: "space-between" },
});

const CustomToolbar = (props) => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmTitle={`Delete book "${props.record.title}"`}
      confirmContent="Are you sure you want to delete this Book? This action is permanent."
    />
  </Toolbar>
);

export const BookEdit = (props) => {
  const refresh = useRefresh();

  return (
    <Edit title={<BookTitle />} undoable={false} {...props}>
      <TabbedForm
        redirect="edit"
        toolbar={<CustomToolbar />}
        warnWhenUnsavedChanges
        submitOnEnter={false}
      >
        <FormTab label="Book">
          <TextInput source="title" fullWidth validate={validateTitle} />
          <TextInput source="slug" fullWidth validate={validateSlug} />
          <TextInput
            source="author"
            fullWidth
            validate={validateShortFieldLength}
          />
          <TextInput
            source="description_short"
            label="Short description"
            multiline
            fullWidth
          />
          <TextInput
            source="description_long"
            label="Long description"
            multiline
            fullWidth
          />
          <BooleanInput source="is_live" fullWidth />
          <BooleanInput source="is_public" fullWidth />
          <TextInput
            source="age_level"
            fullWidth
            validate={validateAgeLevelField}
          />
          <TextInput
            source="illustrators"
            fullWidth
            validate={validateFieldLength}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateInput
              source="copyright_year"
              label="Copyright Year"
              options={{ format: "MM/dd/yyyy" }}
            />
          </MuiPickersUtilsProvider>
        </FormTab>
        <FormTab label="Images" onClick={refresh}>
          <ImageUploader {...props} />
        </FormTab>
        <FormTab label="video" onClick={refresh}>
          <VideoUploader {...props} />
        </FormTab>
        <FormTab label="tags" onClick={refresh}>
          <TagsForm {...props} />
        </FormTab>
        <FormTab label="activities" onClick={refresh}>
          <ActivitiesForm {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
