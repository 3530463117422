import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { validateEmail } from "../../util";
import {
  getDecoratedCountryList,
  getDecoratedLanguageList,
} from "../../utils/countryUtils";
import { PRICE_IDS, USER_SOURCES } from "../../constants";

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="first_name" fullWidth />
      <TextInput source="last_name" fullWidth />
      <TextInput
        source="email"
        fullWidth
        validate={[required(), validateEmail]}
      />
      <TextInput source="password" fullWidth />
      <SelectInput
        source="source"
        choices={USER_SOURCES}
        fullWidth
        defaultValue="manual"
      />
      <SelectInput
        source="price_id"
        label="Subscription Plan"
        choices={PRICE_IDS}
        defaultValue={PRICE_IDS[0]?.id}
        fullWidth
      />
      <SelectInput
        source="country_code"
        label="Country"
        emptyValue={null}
        choices={getDecoratedCountryList()}
        defaultValue={"US"}
      />
      <SelectInput
        source="preferred_language"
        emptyValue={null}
        choices={getDecoratedLanguageList()}
        defaultValue="en"
        allowEmpty
        emptyText="None"
      />
      <TextInput source="coupon" />
    </SimpleForm>
  </Create>
);

export default UserCreate;
